import AboutUsBanner from "../../Components/Main_Components/AboutUsBanner/AboutUsBanner";
import BreadCrumb from "../../Components/Main_Components/Breadcrumb/BreadCrumb";
import OurTestimonials from "../../Sections/OurTestimonials/OurTestimonials";
import WhoWeAre from "../../Sections/WhoWeAre/WhoWeAre";
import OurJourney from "../../Sections/OurJourney/OurJourney";

const About = () => {
  return (
    <>
      <AboutUsBanner
        heading="About Us"
        sub_heading="Our Only Aim - Make Clients Happy!"
      />
      <div>
        <BreadCrumb heading_array={["Home", "About US"]} />
        <WhoWeAre />
        <OurJourney />
        <OurTestimonials />
      </div>
    </>
  );
};

export default About;
