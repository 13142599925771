import BubbleImage from "../../Assets/Images/bubble.png";
import DearCows from "../../Assets/Images/dearcows.png";
import TVSCredit from "../../Assets/Images/tvscredit.png";
import Recruit from "../../Assets/Images/recruit.png";
import Thornton from "../../Assets/Images/thornton.png";
import Stock from "../../Assets/Images/stock.png";
import Perfit from "../../Assets/Images/perfit-1.jpg";

export const OurValuableClientsList = [
  { id: 1, img: BubbleImage },
  { id: 2, img: DearCows },
  { id: 3, img: TVSCredit },
  { id: 4, img: Recruit },
  { id: 5, img: Thornton },
  { id: 6, img: Stock },
  { id: 7, img: Perfit },
  { id: 8, img: BubbleImage },
  { id: 9, img: DearCows },
  { id: 10, img: TVSCredit },
  { id: 11, img: Recruit },
  { id: 12, img: Thornton },
  { id: 13, img: Stock },
  { id: 14, img: Perfit },
  { id: 15, img: BubbleImage },
  { id: 16, img: DearCows },
  { id: 17, img: TVSCredit },
  { id: 18, img: Recruit },
  { id: 19, img: Thornton },
  { id: 20, img: Stock },
  { id: 21, img: Perfit },
];
