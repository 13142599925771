import "./OurServices.css";
import { ButtonSampleOne } from "../../Components/Sub_Components/Buttons/Button";
import { CardsSampleFour } from "../../Components/Main_Components/Cards/Cards";
import { ourServicesList } from "./helper";

const OurServices = () => {
  return (
    <>
      <section className="ourservice_sec">
        <div className="ourservices-container">
          <div className="main_title">
            <h1 className="line">
              <span>Our</span> Services
            </h1>
          </div>
          <h2 className="sub-heading">
            We offer end-to-end services in the IT domain ranging from customized
            mobile app development to enterprise web development.
          </h2>
          <div className="our-services-container-list">
            {ourServicesList.map((item) => {
              return (
                <CardsSampleFour
                  key={item.id}
                  title={item.title}
                  img={item.img}
                  description={item.description}
                />
              );
            })}
          </div>
          <ButtonSampleOne text="CONTACT US FOR FREE CONSULTANCY" />
        </div>
      </section>
    </>
  );
};

export default OurServices;
