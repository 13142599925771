import mobileAppDevelopment from "../../Assets/Services/MobileApplicationDevelopment.png";
import WebDevelopment from "../../Assets/Services/WebDevelopment.png";
import DevOps from "../../Assets/Services/DevOps.png";
import CloudComputing from "../../Assets/Services/CloudComputing.png";

export const ourServicesList = [
  {
    id: 1,
    title: "Mobile App Development",
    img: mobileAppDevelopment,
    description: "Feature-rich apps with a seamless performance",
  },
  {
    id: 2,
    title: "Web Development",
    img: WebDevelopment,
    description: "Robust, reliable, and responsive corporate websites",
  },
  {
    id: 3,
    title: "DevOps",
    img: DevOps,
    description:
      "Customized solutions by integrating technological advancements",
  },
  {
    id: 4,
    title: "Cloud Computing",
    img: CloudComputing,
    description: " Secure and Scalable Cloud-based Apps for Business",
  },
];
