import { useEffect } from "react";
import img from "../../Assets/Svg/inx-logo.svg";
import Timeline from "../../Components/Main_Components/Timeline/Timeline";
import "./OurJourney.css";

const OurJourney = () => {
  useEffect(() => {
    function reveal3() {
      var reveals = document.querySelectorAll(".our-journey-animation");

      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 300;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active3");
        } else {
          reveals[i].classList.remove("active3");
        }
      }
    }
    window.addEventListener("scroll", reveal3);

    return () => {
      window.removeEventListener("scroll", reveal3);
    };
  }, []);

  return (
    <>
      <section className="ourjourney_sec">
        <div className="our-journey-container">
          <div className="main_title our-journey-animation">
            <h3 className="line">
              <span>Our Exciting</span> Journey So Far
            </h3>
          </div>
          <h4 className="our-journey-animation text-center">
            A small team of experienced and dedicated IT professionals has found
            InheritX Solutions back in 2011. Since then, we have never looked back
            in providing high-quality, result-driven, advanced technology
            solutions to our esteemed clients.
          </h4>
          <br /> <br />
          <img
            width="296"
            height="42"
            className="our-journey-animation ourjiurney_inxlogo"
            src={img}
            alt="company logo"
          />
          <br /> <br />
          <div className="our-journey-timeline">
            <Timeline />
          </div>
        </div>
      </section>
    </>
  );
};

export default OurJourney;
