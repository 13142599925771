import BasketBall from "../../Assets/Images/Basketball-1.jpg";
import Cnanetnurse from "../../Assets/Images/CNANETNURSE-1.jpg";
import Weightnot from "../../Assets/Images/WEIGHTNOT-2.jpg";
import PerfitHr from "../../Assets/Images/PERFIT-HR-2.jpg";
import Bubble from "../../Assets/Images/BUBBLE-3.jpg";
import Tvs from "../../Assets/Images/TVS-1.jpg";
import Roula from "../../Assets/Images/ROULA-1.jpg";
import dental from "../../Assets/Images/3C-DENTAL-2.jpg";

export const portFolioList = [
  {
    id: 4018,
    title: "Basketball",
    industry: "Sports App",
    feature_image: BasketBall,
  },
  {
    id: 3813,
    title: "CNANetNurse",
    industry: "HealthCare App",
    feature_image: Cnanetnurse,
  },
  {
    id: 3866,
    title: "WeightNot",
    industry: "Fitness App",
    feature_image: Weightnot,
  },
  {
    id: 3726,
    title: "PerFit-HR",
    industry: "Business",
    feature_image: PerfitHr,
  },
  {
    id: 3795,
    title: "Bubble",
    industry: "Social ",
    feature_image: Bubble,
  },
  {
    id: 3711,
    title: "TVS",
    industry: "Finance",
    feature_image: Tvs,
  },
  {
    id: 3785,
    title: "Roula",
    industry: "Bike Renting",
    feature_image: Roula,
  },
  {
    id: 3765,
    title: "3c-Dental",
    industry: "Healthcare",
    feature_image: dental,
  },
];
