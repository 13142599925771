import Journey_one from "../../Assets/Images/Journey-1.png";
import Journey_two from "../../Assets/Images/Journey-2.png";
import Journey_three from "../../Assets/Images/Journey-3.png";
import Journey_four from "../../Assets/Images/Journey-4.png";
import Journey_five from "../../Assets/Images/Journey-5.png";
import Journey_six from "../../Assets/Images/Journey-6.png";
import Journey_seven from "../../Assets/Images/Journey-7.png";
import Journey_eight from "../../Assets/Images/Journey-8.png";
import Journey_nine from "../../Assets/Images/Journey-9.png";

export const OurJourneyList = [
  {
    id: 1,
    year: "2011",
    feature_image: Journey_one,
    title: "Born of a Star",
    description: "We started with a small team of enthusiastic techies.",
  },
  {
    id: 2,
    year: "2012",
    feature_image: Journey_two,
    title: "Steady Growth",
    description:
      "Our small setup turned into one of the fastest-growing IT companies. We registered InheritX Solutions in NASSCOM, IGDA, GESIA, and other reputed groups of organizations.",
  },
  {
    id: 3,
    year: "2013",
    feature_image: Journey_three,
    title: "Rise in Style",
    description: "Shifted to a new office and the employee strength became 50",
  },
  {
    id: 4,
    year: "2014",
    feature_image: Journey_four,
    title: "International Footprints",
    description: "Established offices in the USA, Canada, and Australia",
  },
  {
    id: 5,
    year: "2015",
    feature_image: Journey_five,
    title: "Started gaining Recognition",
    description:
      "Brought massive changes in business policy to serve our clients better, started digital marketing services, and voted as the Best Mobile Application Development Company by GESIA.",
  },
  {
    id: 6,
    year: "2016",
    feature_image: Journey_six,
    title: "Milestone Year",
    description:
      "Crossed the milestone of 100+ employees and $1million+ business on Upwork",
  },
  {
    id: 7,
    year: "2017",
    feature_image: Journey_seven,
    title: "New Horizons",
    description:
      "Started assisting startups as their development partner and started developing high-end solutions in the niche technology segments- IoT and BLE along with AR/VR.",
  },
  {
    id: 8,
    year: "2018",
    feature_image: Journey_eight,
    title: "Leading Outsourcing Agency",
    description:
      "Attended MWCA LA and represented InheritX Solutions as a leading global outsourcing agency",
  },
  {
    id: 9,
    year: "2019",
    feature_image: Journey_nine,
    title: "And The Journey Goes On",
    description:
      "Celebrating 8 Years of Successful Accomplishment of Project across Different Industry Sectors and expanded services to AI and Bitcoin. We also started working on Indoor Navigation projects and Sports Data Analytics applications.",
  },
  {
    id: 10,
    year: "Continue",
  },
];
