import "./Timeline.css";
import { OurJourneyList } from "../../../Sections/OurJourney/helper";
import { useEffect } from "react";

const Timeline = () => {
  useEffect(() => {
    function reveal() {
      var reveals = document.querySelectorAll(".reveal");

      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 150;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    }
    window.addEventListener("scroll", reveal);

    return () => {
      window.removeEventListener("scroll", reveal);
    };
  }, []);

  return (
    <div>
      {OurJourneyList.map((item) => {
        return (
          <div className="timeline-container" key={item.id}>
            <span className="timeline-breaker">
              <div className="button-sample-three button-breaker">
                <h2 className="linear-wipe">{item.year}</h2>
              </div>
              <div className="timeline-breaker-line"></div>
            </span>
            {item.id % 2 !== 0 ? (
              <div className="timeline-components reveal fade-bottom">
                <div className="timeline-components-description timeline-border timeline-left">
                  <h2>{item.title} </h2>
                  <h3>{item.description}</h3>
                </div>
                <div
                  className="timeline-image"
                  style={{ textAlign: "left", paddingLeft: "50px" }}
                >
                  <img
                    width="380"
                    height="250"
                    src={item.feature_image}
                    alt="journey_date"
                  />
                </div>
              </div>
            ) : (
              <div className="timeline-components reveal fade-bottom">
                <div
                  className="timeline-border timeline-image"
                  style={{ textAlign: "right", paddingRight: "50px" }}
                >
                  <img
                    width="380"
                    height="250"
                    src={item.feature_image}
                    alt="journey-date"
                  />
                </div>
                <div className="timeline-components-description timeline-right">
                  <h2>{item.title} </h2>
                  <h3>{item.description}</h3>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Timeline;
