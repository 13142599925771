import "./OurTestimonials.css";
// import OurTestimonialsImage from '../../Assets/Images/testimonial_bg.jpg'
import { testimonialList } from "./helper";
import { CardSampleTestimonial } from "../../Components/Main_Components/Cards/Cards";
import { useEffect, useState } from "react";

const OurTestimonials = () => {
  const [windowDimensions, setWindowDimensions] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [currentPage, setCurrentPage] = useState(1);

  let postPerPage = windowDimensions > 932 ? 3 : windowDimensions > 668 ? 2 : 1;

  const lastPostIndex = currentPage * postPerPage;
  const firstPostIndex = lastPostIndex - postPerPage;
  const currentPosts = testimonialList.slice(firstPostIndex, lastPostIndex);

  return (
    <>
      <div className="partners-container-our-testimonials">
        <div className="main_title-our-testimonials">
          <h3 className="line">
            <span>Our</span> Testimonials
          </h3>
        </div>
        <div className="our-testimonial-content">
          <h4 className="sub-heading2 sub-heading-testimonial">
            Every service we provide, adds a new member into our dear family.
          </h4>
        </div>
        <div></div>

        <div className="our-testimonial-cards">
          <div
            className="our-testimonial-cards-arrow"
            onClick={() => {
              if (currentPage > 2) setCurrentPage(currentPage - 1);
              else setCurrentPage(1);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#060828"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevron-left"
            >
              <polyline points="15 18 9 12 15 6"></polyline>
            </svg>
          </div>
          <div className="our-testimonial-cards-inner">
            {currentPosts.map((item) => {
              return (
                <div key={item.id}>
                  <CardSampleTestimonial
                    id={item.id}
                    title={item.title}
                    content={item.content}
                    feature_image={item.feature_image}
                    designation={item.designation}
                  />
                </div>
              );
            })}
          </div>
          <div
            onClick={() => {
              if (currentPage < 5) setCurrentPage(currentPage + 1);
            }}
            className="our-testimonial-cards-arrow"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#060828"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevron-right"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurTestimonials;
