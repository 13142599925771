import { CardSampleThree } from "../../Components/Main_Components/Cards/Cards";
import { teamImageList } from "./helper";
import "./Teams.css";

const Teams = () => {
    return (
        <>
            <section className="team_sec">
                <div className="portfolio-container-for-team">
                    <div className="main_title">
                        <h4 className="line">
                            <span>Our</span> Team
                        </h4>
                    </div>
                    <h3 className="sub-heading">People who guide us and lead InheritX Solutions toward excellence with their unique vision</h3>
                    <div className="portfolio-container-list ourteam_card">
                        {teamImageList.map((item) => {
                            return (
                                <div key={item.id} className='team_single_box'>
                                    <CardSampleThree
                                        img={item.feature_image}
                                        // industry={item.industry}
                                        title={item.title}
                                        subtitle={item.subtitle}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        </>
    );
};

export default Teams;
