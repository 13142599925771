import { useEffect } from "react";
import "./WhoWeAre.css";

const WhoWeAre = () => {
  useEffect(() => {
    function reveal2() {
      var reveals = document.querySelectorAll(".who-we-are-description");

      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 150;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active2");
        } else {
          reveals[i].classList.remove("active2");
        }
      }
    }
    window.addEventListener("scroll", reveal2);

    return () => {
      window.removeEventListener("scroll", reveal2);
    };
  }, []);

  return (
    <>
      <section className="whoweare_sec">
        <div className="who-we-are-container">
          <div className="main_title">
            <h2 className="line">
              <span>Who</span> We Are
            </h2>
          </div>
          <h3
            className="sub-heading who-we-are-description"
            style={{ width: "100%" }}
          >
            Since 2011, excellent customer service is not just a phrase, but a
            core value of InheritX Solutions. Developing high-quality solutions in
            web and mobile app domains is a way of life at InheritX for over eight
            years. Our client-centric approach and no compromise on performance
            have made us an undisputed leader in mobile app, web, game, and
            digital marketing.
          </h3>

          <h3
            className="sub-heading mb-0 who-we-are-description "
            style={{ width: "100%" }}
          >
            At InheritX, our aim remains the same since inception- make and keep
            our clients happy. This is the reason why we have a large clientele
            across different industry sectors worldwide. Our happy clients include
            startups, SMEs, and even a few of the Fortune 500 companies. Our
            in-house team of experienced developers makes it possible to transform
            your creative concepts into innovative IT solutions.
          </h3>
        </div>
      </section>
    </>
  );
};

export default WhoWeAre;
