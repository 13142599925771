import "./Header.css";
import img from "../../../Assets/Svg/inx-logo.svg";
import downArrow from "../../../Assets/Images/down-arrow.png";
import { Link } from "react-router-dom";
import { ButtonSampleOne } from "../../Sub_Components/Buttons/Button";
import { useState } from "react";

const Header = () => {
  const [hamburgerStatus, setHumburgerStatus] = useState(false);
  return (
    <>
      <div className="header-main-container">
        <div className="header-left-section">
          <Link className="header-main-container-link" to="/">
            <img width="300" height="43" alt="Inheritx-logo" src={img} />
          </Link>
        </div>
        <div
          className={`${hamburgerStatus ? "my-class" : ""
            } header-middle-section`}
          style={{ display: `${hamburgerStatus ? "block" : ""}` }}
        >
          <div className="header-middle-section-container">
            <span>
              <div className="header-middle-section-container-options">
                Company
                <img
                  alt="Inheritx-logo"
                  width="15px"
                  height="15px"
                  src={downArrow}
                />
              </div>
              <div className="header-middle-section-container-company-options">
                <ul>
                  <li>Why Inheritx</li>
                  <Link
                    style={{ textDecoration: "none", color: "#060828" }}
                    to="/about"
                  >
                    <li> About Us </li>
                  </Link>
                  <li>Hiring Model</li>
                  <li>Career</li>
                </ul>
              </div>
            </span>
            <Link className="header-main-container-link" to="/services">
              <span className="header-middle-section-container-options">
                Services{" "}
                <img
                  alt="Inheritx-logo"
                  width="15px"
                  height="15px"
                  src={downArrow}
                />
              </span>
              <div
                className="header-middle-section-container-company-options"
                id="header-services-options"
              >
                <ul>
                  <li>Mobile App Development</li> <li> Web Development </li>
                  <li>Other Services</li>
                </ul>
              </div>
            </Link>
            <Link className="header-main-container-link" to="/hire-resources">
              <span className="header-middle-section-container-options">
                Hire Us{" "}
                <img
                  alt="Inheritx-logo"
                  width="15px"
                  height="15px"
                  src={downArrow}
                />
              </span>
              <div id="header-hire-us-options" className="header-middle-section-container-company-options">
                <ul>
                  <li>Hire Reactjs Developer</li> <li>Hire NodeJs Developer</li>
                  <li>Other Vaccancies</li>
                </ul>
              </div>
            </Link>
          </div>
          <div className="header-middle-section-container">
            <Link
              className="header-main-container-link"
              id="header-blog-options"
              to="/blog"
            >
              Blog
            </Link>
            <Link
              className="header-main-container-link"
              id="header-portfolio-options"
              to="/portfolio"
            >
              Our Portfolio
            </Link>
          </div>
        </div>
        <div className="header-right-section">
          <ButtonSampleOne text="ContactI Us" />
        </div>

        <div
          className="hamburger-menu"
          onClick={() => setHumburgerStatus(!hamburgerStatus)}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>


    </>
  );
};

export default Header;
