// import BasketBall from "../../Assets/Images/Basketball-1.jpg";
// import Cnanetnurse from "../../Assets/Images/CNANETNURSE-1.jpg";
// import Weightnot from "../../Assets/Images/WEIGHTNOT-2.jpg";
// import PerfitHr from "../../Assets/Images/PERFIT-HR-2.jpg";
// import Bubble from "../../Assets/Images/BUBBLE-3.jpg";
// import Tvs from "../../Assets/Images/TVS-1.jpg";
// import Roula from "../../Assets/Images/ROULA-1.jpg";
// import dental from "../../Assets/Images/3C-DENTAL-2.jpg";
import SandeepSirImage from "../../Assets/Images/sandeep_sir.jpg";
import MeghSirImage from "../../Assets/Images/HR.jpg";
import RiddhishSirImage from "../../Assets/Images/riddhish_sir.png";
import DhwanikGandhiImage from "../../Assets/Images/dhwanik_gandhi.jpg";

export const teamImageList = [
  {
    id: 1,
    title: "Sandip Modi",
    subtitle: "CEO",
    // industry: "Sports App",
    feature_image: SandeepSirImage,
  },
  {
    id: 2,
    title: "Riddhish Ojha",
    subtitle: "Project Manager",
    // industry: "HealthCare App",
    feature_image: RiddhishSirImage,
  },
  {
    id: 3,
    title: "Dhwanik Gandhi",
    subtitle: "Product Manager",
    // industry: "Sports App",
    feature_image: DhwanikGandhiImage,
  },
  {
    id: 4,
    title: "Megh Makwana",
    subtitle: "HR Executive",
    // industry: "HealthCare App",
    feature_image: MeghSirImage,
  },
];
