import "./OurValuableClients.css";
import { OurValuableClientsList } from "./helper";

const OurValuableClients = () => {
  return (
    <>
      <section className="ourvaluable_client_sec">
        <div className="portfolio-container ourvaluable_client_container">
          <div className="main_title">
            <h4 className="line">
              <span>Our Valuable</span> Clients
            </h4>
          </div>
          <h3 className="sub-heading">
            We are pleased to serve one of the most reputed and respected brands
            across different sectors globally
          </h3>
          <div className="client_slider">
            <div className="client-slider-track">
              {OurValuableClientsList.map((item) => {
                return (
                  <div key={item.id} className="client_logo">
                    <img src={item.img} alt="bubble_logo" />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="button-animate">
            <button className="client-btn" type="button">
              Let us WOW you
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurValuableClients;
