import Amazon from "../../Assets/Images/amazon.png";
import Paypal from "../../Assets/Images/partner.png";
import Upwork from "../../Assets/Images/upwork.png";
import GoodFirms from "../../Assets/Images/good.png";
import Guru from "../../Assets/Images/guru.png";
import Clutch from "../../Assets/Images/clutouc.png";
import Glesia from "../../Assets/Images/gesia.png";

export const partnersList = [
  {
    id: 1,
    feature_image: Amazon,
  },
  {
    id: 2,
    feature_image: Paypal,
  },
  {
    id: 3,
    feature_image: Upwork,
  },
  {
    id: 4,
    feature_image: GoodFirms,
  },
  {
    id: 5,
    feature_image: Guru,
  },
  {
    id: 3711,
    feature_image: Clutch,
  },
  {
    id: 3785,
    feature_image: Glesia,
  },
];
