import "./BreadCrumb.css";

const BreadCrumb = ({ heading_array }) => {
  return (
    <section className="breadcrumb-section">
      {heading_array.map((item, index) => {
        return (
          <div key={index}>
            {item} <span>/</span>
          </div>
        );
      })}
    </section>
  );
};
export default BreadCrumb;
