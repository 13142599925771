import "./CompanyIntroduction2.css";

const CompanyIntro2 = () => {
  return (
    <>
      <section className="about_section">
        <div className="company-intro2-main-container">
          <div className="inx-solutions">
            <div className="company-intro2-main-container-content-data">
              <div className="main_title">
                <h4 className="line">
                  <span>Inheritx </span> Solutions
                </h4>
              </div>
              <h3>Because We Deliver the Best Solutions to Remain Unbeatable</h3>
              <p className="tabletmobile-mb-20">
                At InheritX, customer service is not just a word, but a core
                value. Since inception in 2011, we have made quality a habit to
                serve our global clientele with the best-in-class web and mobile
                app solutions. We have strong teams of experienced professionals
                who can handle the project of any size and scale to bring
                innovative IT solutions in the given deadlines. We thrive through
                ensuring growth of our clients’ business by providing futuristic
                and feature-rich solutions in web and mobile app development
                domain.
              </p>
              <p className="tabletmobile-mb-20">
                InheritX Solutions believes in client-centric approach and
                delivering excellent results with the utmost transparency. Over
                97% of client retention ratio indicates how serious we are in
                establishing and maintaining good relationships with our clients.
                24/7 technical assistance, agile methodology, and dedicated
                approach have remained our characteristics since inception. We
                ensure the safety of your project details and offer you the
                top-notch solutions to assist you to stay ahead of the curve.
              </p>
            </div>
            <div className="company-intro-hexagons">
              <div style={{ width: "100%", float: "left" }}>
                <div className="one">
                  <div className="one-text">
                    <h2>120+</h2>
                    <h4>
                      Development
                      <br />
                      Staff
                    </h4>
                  </div>
                </div>
                <div className="two">
                  <div className="two-text">
                    <h2>850+</h2>
                    <h4>
                      Projects
                      <br />
                      Delivered
                      <br />
                      Successfully
                    </h4>
                  </div>
                </div>
                <div className="three">
                  <div className="three-text">
                    <h2>400+</h2>
                    <h4>
                      Verified 5*
                      <br /> Client Reviews
                    </h4>
                  </div>
                </div>
              </div>

              <div style={{ width: "100%", float: "left" }}>
                <div className="four">
                  <div className="four-text">
                    <h2>97%</h2>
                    <h4>
                      Client
                      <br /> Retention Ratio
                    </h4>
                  </div>
                </div>
              </div>

              <div style={{ width: "100%", float: "left" }}>
                <div className="five">
                  <div className="five-text">
                    <h2>24/7</h2>
                    <h4>Support</h4>
                  </div>
                </div>

                <div className="six">
                  <div className="six-text">
                    <h2>40+</h2>
                    <h4>
                      Industries
                      <br />
                      Served
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CompanyIntro2;
