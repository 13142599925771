import { CardSampleOne } from "../../Components/Main_Components/Cards/Cards";
import { portFolioList } from "./helper";
import "./Portfolio.css";

const Portfolio = () => {
  return (
    <>
      <section className="portolio_sect">
        <div className="portfolio-container">
          <div className="main_title">
            <h3 className="line">
              <span>Our</span> Portfolio
            </h3>
          </div>
          <h3 className="sub-heading">
            Here is a glimpse of our work that says the story of our focused
            approach and dedicated efforts to meet the client’s business
            requirements.
          </h3>
          <div className="portfolio-container-list portfolio-grid">
            {portFolioList.map((item) => {
              return (
                <div key={item.id} className="portfolio-grid-width">
                  <CardSampleOne
                    img={item.feature_image}
                    industry={item.industry}
                    title={item.title}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Portfolio;
