import IndianFlag from "../../../Assets/Images/flag-of-India-1.jpg";
import AmericaFlag from "../../../Assets/Images/flag-of-United-States-of-America-1.jpg";
import JapanFlag from "../../../Assets/Images/Japan-2.jpg";
import GermanyFlag from "../../../Assets/Images/flag-of-Germany-1.jpg";
import FaceBookSvg from "../../../Assets/Svg/facebook-letter-logo.svg";
import InstagramSvg from "../../../Assets/Svg/instagram.svg";
import TwitterSvg from "../../../Assets/Svg/twitter.svg";
import LinkedinSvg from "../../../Assets/Svg/linkedin-logo.svg";

export const inheritxSocialAccounts = [
  { id: 1, account: "Facebook", feature_img: FaceBookSvg },
  { id: 2, account: "Twitter", feature_img: TwitterSvg },
  { id: 3, account: "LinkedIn", feature_img: LinkedinSvg },
  { id: 4, account: "Instagram", feature_img: InstagramSvg },
];

export const footerCompanyAddressList = [
  {
    id: 1,
    countryFlag: AmericaFlag,
    countryName: "UNITED STATES",
    address: "222 BROADWAY, New York, NY, 10038, United States",
  },
  {
    id: 2,
    countryFlag: GermanyFlag,
    countryName: "GERMANY",
    address: "Kloster 3, 79713, Bad Säckingen, Germany",
  },
  {
    id: 3,
    countryFlag: JapanFlag,
    countryName: "JAPAN",
    address: "1-36-13 hashiba,  TAITO-KU Tokyo,  Japan",
  },
  {
    id: 4,
    countryFlag: IndianFlag,
    countryName: "INDIA",
    address:
      "8th Floor, Panchdhara Complex, S G Highway, Bodakdev, Ahmedabad - 380054",
  },
];
