import "./Cards.css";
import testimonialLogo from "../../../Assets/Testimonial_Images/bubble.png";

export const CardSampleOne = ({ img, industry, title }) => {
  return (
    <div className="ourportfolio_card">
      <div className="card-sample-one-top-container">
        <img src={img} width="255" height="260" alt={`${industry} Client`} />
        <h3>
          <div>{industry}</div>
        </h3>
      </div>
      <div className="card-sample-one-bottom-container">{title}</div>
    </div>
  );
};

export const CardSampleTwo = ({ img, id }) => {
  return (
    <div className="card-sample-two-container">
      <img src={img} width="160" height="70" alt={id} />
    </div>
  );
};

export const CardSampleThree = ({ img, title, subtitle }) => {
  return (
    <div>
      <div className="card-sample-one-top-container">
        <img src={img} width="250" height="225" alt={`${title} Client`} />
      </div>
      <div className="team_name">
        <p>{title}</p>
        <span>{subtitle}</span>
      </div>
    </div>
  );
};

export const CardsSampleFour = ({ title, img, description }) => {
  return (
    <div className="ourservice-card">
      <div className="card-sample-four-container">
        <div className="card-sample-four-image">
          <img src={img} width="102" height="102" alt={`${title}`} />
        </div>
        <div className="card-sample-four-container-description">
          <h2>{title}</h2>
          <div>{description}</div>
        </div>
      </div>
    </div>
  );
};

export const CardSampleTestimonial = ({
  id,
  title,
  content,
  feature_image,
  designation,
}) => {
  return (
    <>
      <div className="testimonials_section-for-testimonial-card">
        <img
          width="89"
          height="85"
          src={testimonialLogo}
          style={{ marginBottom: "0.6rem" }}
          alt="testimonial-logo"
        />
        <div className="testimonial_quotes-for-card">{content}</div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div>
          <img
            width="80"
            height="80"
            src={feature_image}
            alt="testimonial-person-img"
          />
        </div>
        <div>
          <div className="testimonials_section-for-testimonial-name">
            {title}
          </div>
          <div className="testimonials_section-for-testimonial-designation">
            {designation}
          </div>
        </div>
      </div>
    </>
  );
};
