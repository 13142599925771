import { useEffect } from "react";
import { CardSampleTwo } from "../../Components/Main_Components/Cards/Cards";
import { ButtonSampleOne } from "../../Components/Sub_Components/Buttons/Button";
import { partnersList } from "./helper";
import "./Partners.css";

const Partners = () => {
  useEffect(() => {
    function reveal4() {
      var reveals = document.querySelectorAll(".partners-second-container ");

      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 100;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("fadeinup_animation");
        } else {
          reveals[i].classList.remove("fadeinup_animation");
        }
      }
    }
    window.addEventListener("scroll", reveal4);

    return () => {
      window.removeEventListener("scroll", reveal4);
    };
  }, []);

  return (
    <>
      <section className="ourpartners_sec">
        <div className="partners-container">
          <div className="main_title">
            <h4 className="line">
              <span>Our</span> Partners
            </h4>
          </div>
          <h3 className="sub-heading">
            We Feel Pride in Having Partnerships with Some of the Most Reputed
            Companies in the World
          </h3>
          <div className="partners-container-list">
            {partnersList.map((item) => {
              return (
                <div key={item.id} className="partners-card">
                  <CardSampleTwo img={item.feature_image} id={item.id} />
                </div>
              );
            })}
          </div>
        </div>
        <div className="partners-transform-container">
          <div className="partners-transform-field">
            <div className="partners-second-container ">
              <h1> Let’s discuss your app idea and implement the innovative mobility
                solution for your business needs.</h1>
            </div>
            <div
              className="partners-second-container expertbtn"
            >
              {/* fadeinup_animation */}
              <ButtonSampleOne text="TALK TO OUR EXPERTS" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Partners;
