import "./Input.css";

export const InputSampleOne = ({ placeholder }) => {
  return (
    <>
      <input
        type="text"
        className="input-sample-one"
        placeholder={placeholder}
      />
    </>
  );
};
