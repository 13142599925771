import Edward from "../../Assets/Testimonial_Images/Ed-1.jpg";
import Saady from "../../Assets/Testimonial_Images/Saady.png";
import Simon from "../../Assets/Testimonial_Images/simon-2.jpg";
import Badri from "../../Assets/Testimonial_Images/Badri_Hiriyur-2.jpg";
import Dorain from "../../Assets/Testimonial_Images/Capture_dorian-1.jpg";

export const testimonialList = [
  {
    id: 1,
    title: "Saady",
    content:
      "Inheritx have proven themselves to be dependable with solid problem solving and technical skills. They are persistent, reliable, flexible and responsive.",
    feature_image: Saady,
    designation: "Developer",
  },
  {
    id: 2,
    title: "Simon",
    content:
      "Inheritx has done a fabulous job. We want to continue using them in the future and recommend them to all developers looking for professional, high quality work.",
    feature_image: Simon,
    designation: "Developer",
  },
  {
    id: 3,
    title: "Dorain",
    content:
      "The team has been fantastic, I have been working with them for nearly 2 years now and have not been able to find a fault in their performance or attitude. They are extremely professional and polite wi",
    feature_image: Dorain,
    designation: "Developer",
  },
  {
    id: 4,
    title: "Badri",
    content:
      "InheritX is very professional and articulate in their approach to this project. The most impressive thing is the input and intelligent contributions they have made in the design of the app.",
    feature_image: Badri,
    designation: "Manager",
  },
  {
    id: 5,
    title: "Edward",
    content:
      "Overall, I was very satisfied with Inheritx. They are hard workers, very reliable, and very flexible. Overall, I would recommend INX team for any development work.",
    feature_image: Edward,
    designation: "CEO",
  },
  {
    id: 6,
    title: "Saady",
    content:
      "Inheritx have proven themselves to be dependable with solid problem solving and technical skills. They are persistent, reliable, flexible and responsive.",
    feature_image: Saady,
    designation: "Developer",
  },
  {
    id: 7,
    title: "Simon",
    content:
      "Inheritx has done a fabulous job. We want to continue using them in the future and recommend them to all developers looking for professional, high quality work.",
    feature_image: Simon,
    designation: "Developer",
  },
  {
    id: 8,
    title: "Dorain",
    content:
      "The team has been fantastic, I have been working with them for nearly 2 years now and have not been able to find a fault in their performance or attitude. They are extremely professional and polite wi",
    feature_image: Dorain,
    designation: "Developer",
  },
  {
    id: 9,
    title: "Badri",
    content:
      "InheritX is very professional and articulate in their approach to this project. The most impressive thing is the input and intelligent contributions they have made in the design of the app.",
    feature_image: Badri,
    designation: "Manager",
  },
  {
    id: 10,
    title: "Edward",
    content:
      "Overall, I was very satisfied with Inheritx. They are hard workers, very reliable, and very flexible. Overall, I would recommend INX team for any development work.",
    feature_image: Edward,
    designation: "CEO",
  },
  {
    id: 11,
    title: "Saady",
    content:
      "Inheritx have proven themselves to be dependable with solid problem solving and technical skills. They are persistent, reliable, flexible and responsive.",
    feature_image: Saady,
    designation: "Developer",
  },
  {
    id: 12,
    title: "Simon",
    content:
      "Inheritx has done a fabulous job. We want to continue using them in the future and recommend them to all developers looking for professional, high quality work.",
    feature_image: Simon,
    designation: "Developer",
  },
  {
    id: 13,
    title: "Dorain",
    content:
      "The team has been fantastic, I have been working with them for nearly 2 years now and have not been able to find a fault in their performance or attitude. They are extremely professional and polite wi",
    feature_image: Dorain,
    designation: "Developer",
  },
  {
    id: 14,
    title: "Badri",
    content:
      "InheritX is very professional and articulate in their approach to this project. The most impressive thing is the input and intelligent contributions they have made in the design of the app.",
    feature_image: Badri,
    designation: "Manager",
  },
  {
    id: 15,
    title: "Edward",
    content:
      "Overall, I was very satisfied with Inheritx. They are hard workers, very reliable, and very flexible. Overall, I would recommend INX team for any development work.",
    feature_image: Edward,
    designation: "CEO",
  },
];
