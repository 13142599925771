import introImage from "../../Assets/Images/slide3.png";
import { ButtonSampleOne } from "../../Components/Sub_Components/Buttons/Button";
import "./CompanyIntroduction.css";

const CompanyIntro = () => {
  return (
    <>
      <section className="home-banner-sec">
        <div className="home-banner-container">
          <div className="company-intro-main-container">
            <div className="company-intro-main-container-content-data">
              <h1 className="mobilityFont">MOBILITY</h1>
              <span className="goMobileGoCloud">GO MOBILE GO CLOUD GO DIGITAL</span>
              <p className="descriptionText">
                We have developed 500+ mobile apps for startups, SMEs, and
                established businesses worldwide. Our in-house mobile app developers
                can work on any size project to deliver top-notch mobility
                solutions.
              </p>
              <div className="w-100 float-left text-md-left text-sm-center">
                <ButtonSampleOne text="Click for Free Consultancy" />
              </div>
            </div>
            <div className="imgDiv">
              <img
                // width="876"
                // height="675"
                fetchpriority="high"
                alt="Inheritx-introduction"
                src={introImage}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CompanyIntro;
