import {
  ButtonSampleOne,
  ButtonSampleTwo,
} from "../../Sub_Components/Buttons/Button";
import { InputSampleOne } from "../../Sub_Components/Input/input";
import "./Footer.css";
import TelePhoneSvg from "../../../Assets/Images/call.png";
import MailPng from "../../../Assets/Images/mail.png";
import { footerCompanyAddressList, inheritxSocialAccounts } from "./helper";

const Footer = () => {
  return (




    <footer>
      <div className="footer-container">
        <div className="footer-main-component">
          <div className="footer-main-component-form">
            <h2> Contact Information</h2>
            <hr />
            <form className="contact_form">
              <div className="contact_form_grid">
                <InputSampleOne placeholder="Name*" />
                <InputSampleOne placeholder="Email*" />
              </div>
              <div className="contact_form_grid">
                <InputSampleOne placeholder="Country*" />
                <InputSampleOne placeholder="Phone*" />
              </div>
              <div className="contact_form_grid">
                <InputSampleOne placeholder="Project Type*" />
                <select>
                  <option value="Select Your Budget*" disabled>
                    Select Your Budget*
                  </option>
                  <option value="$5000<">$5000</option>
                  <option value="$5000 - $25,000">$5000 - $25,000</option>
                  <option value="Above $25000">Above $25000</option>
                </select>
              </div>
              <div className="contact_form_grid">
                <textarea placeholder="Brief Your Details*" />
              </div>
              <div>
                <ButtonSampleOne text="Schedule a free Consultation" />
              </div>
            </form>
          </div>

          <div className="footer-main-component-right-section">
            <h2>We are Here </h2>
            <hr />
            <div className="footer-company-address-list">
              {footerCompanyAddressList.map((item) => {
                return (
                  <div className="footer-company-address" key={item.id}>
                    <div>
                      <img
                        width="75"
                        height="44"
                        alt={item.countryName}
                        src={item.countryFlag}
                      />
                    </div>
                    <div>
                      <div className="footer-company-address-country-name">
                        <h6>
                          {item.countryName}
                        </h6>
                      </div>
                      <div>
                        <p>
                          {item.address}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>


            <div className="footer_contact">
              <div className="footer_contact_info">
                <img
                  alt="telePhoneNumber"
                  src={TelePhoneSvg}
                />{" "}
                <span> +1(347) 394-0007</span>
              </div>
              <div className="footer_contact_info">
                <img
                  alt="telePhoneNumber"
                  src={MailPng}
                />
                <span>contact@inheritx.com</span>
              </div>
            </div>

            <div style={{ marginTop: "30px" }}>
              <h3> Connect With Us :</h3>
              {inheritxSocialAccounts.map((item) => {
                return (
                  <ButtonSampleTwo
                    text={item.feature_img}
                    alt={`${item.account} account`}
                    key={item.id}
                  />
                );
              })}
            </div>
          </div>
        </div>

      </div>
      <div className="footer-container-copywriter-container">
        Copyright © 2023 InheritX Solutions. All Rights Reserved.
      </div>
    </footer>
  );
};

export default Footer;
