import "./AboutUsBanner.css";

const AboutUsBanner = ({ heading, sub_heading }) => {
  return (
    <>
      <section className="about-us-section">
        <div className="main_title2" style={{ color: "white" }}>
          <h1 className="line">{heading}</h1>
        </div>
        <h2 className="banner-title">{sub_heading}</h2>
      </section>
    </>
  );
};

export default AboutUsBanner;
