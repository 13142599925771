import { HashRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import Career from "./Pages/Career/Career";
import Services from "./Pages/Services/Services";
import Header from "./Components/Main_Components/Header/Header";
import Footer from "./Components/Main_Components/Footer/Footer";
import Portfolio from "./Pages/Portfolio/portfolio";
import Blog from "./Pages/Blog/Blog";
import HireUs from "./Pages/Hire_us/Hire_us";

function App() {
  return (
    <HashRouter>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/career-builder" element={<Career />} />
        <Route exact path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/hire-resources" element={<HireUs />} />
      </Routes>
      <Footer />
    </HashRouter>
  );
}

export default App;
