import CompanyIntro from "../../Sections/CompanyIntroduction/CompanyIntroduction";
import CompanyIntro2 from "../../Sections/CompanyIntroduction2/CompanyIntroduction2";
import OurServices from "../../Sections/OurServices/OurServices";
import Partners from "../../Sections/Partners/Partners";
import Portfolio from "../../Sections/Portfolio/Portfolio";
import Teams from "../../Sections/Teams/Teams";
import OurValuableClients from "../../Sections/OurValuableClients/OurValuableClients";

const Home = () => {
  return (
    <>

      <CompanyIntro />
      <CompanyIntro2 />
      <OurServices />
      <Portfolio />
      <Partners />
      <Teams />
      <OurValuableClients />
    </>
  );
};

export default Home;
