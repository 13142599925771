import "./Button.css";

export const ButtonSampleOne = ({ text }) => {
  return (
    <>
      <button className="button-sample-one">{text}</button>
    </>
  );
};

export const ButtonSampleTwo = ({ text, alt }) => {
  return (
    <>
      <button className="button-sample-two">
        <img src={text} alt={alt} width="18" height="18" />
      </button>
    </>
  );
};

export const ButtonSampleThree = ({ text }) => {
  return (
    <>
      <div className="button-sample-three">
        <h2 className="linear-wipe">{text}</h2>
      </div>
    </>
  );
};
